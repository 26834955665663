* {
  box-sizing: border-box;
}

.bento-box {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 10px;
  align-items: flex-start;
  overflow: hidden;
}

.left-column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  max-width: 20%;
  gap: inherit;
}

.deck-filter,
.matchup-count,
.data-table {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.deck-filter,
.matchup-count {
  width: 100%;
}

.data-table {
  flex-grow: 0;
  flex-shrink: 1;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .bento-box {
    flex-direction: column;
  }

  .left-column,
  .data-table,
  .deck-filter,
  .matchup-count {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 100%;
    flex-basis: auto;
  }
}