* {
  box-sizing: border-box;
}

.bento-box-sim {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 10px;
  align-items: flex-start;
  /* overflow: hidden; Consider if 'hidden' is necessary, as it might clip content */
}

.deck-filter-sim,
.data-table-sim {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 100%;
}

.data-table-sim {
  flex-grow: 1; /* Allows this element to grow and fill available space */
  overflow-x: auto; /* For horizontal scrolling if content is wider than screen */
}

@media (max-width: 768px) {
  .bento-box-sim {
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }

  .data-table-sim,
  .deck-filter-sim {
    flex-basis: auto; /* Resetting flex-basis for smaller screens */
    max-width: 100%; /* Full width on smaller screens */
    min-width: 100%; /* Full width on smaller screens */
  }
}
