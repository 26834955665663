.App {
  text-align: center;
}

.navigation {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.matchupForm {
  padding-top: 40px;
}

.accordion-matchup-component {
  padding-top: 20px;
  padding-bottom: 80px;
}
