.simulator-table {
  border-spacing: 0;
  background: #fff;
  margin-bottom: 20px; 
}

.simulator-table th, .simulator-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}

.simulator-table th {
  background-color: #d3f3fd;
  font-weight: bold;
}

.record-style {
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;
  padding: 5px 10px;
  margin: -7px -12px; /* To prevent adjustment to the table dimensions, margin needs to offset padding + border */
  border: 1px solid #000000;
}

@media (min-width: 1024px) {
  .inputs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

.deck-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.round-input {
  display: flex;
  align-items: center;
}

.inputs-container {
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
}

.divider {
  margin: 16px !important;
}

.deck-input-text {
  margin-left: 2.5rem;
}

.deck-input-field {
  margin-right: 2.5rem;
}

.deck-input {
  margin: 4px;
}

.sim-tournament-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  /* margin-right: 14px; */
}

.data-btn {
  padding: 10px 15px;
  font-size: 16px;
}

.sim-tournament-btn:hover {
  background-color: #146bc9;
}

.sim-btn-bottom {
  display: flex;
  align-items: center;
  margin: 8px 4px 4px 4px;
  gap: 12px;
}

.overlay-style {
  position: fixed; /* Fixed relative to the viewport */
  top: 0;
  left: 0;
  width: 100%; /* Full width of the viewport */
  height: 100%; /* Full height of the viewport */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black */
  z-index: 2000; /* High z-index to ensure it covers other content */
}